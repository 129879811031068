<script setup>
  /**
   * 
   * Komponens: gomb, amely a mobil nézetes menüt megjeleníti
   * 
   */

    // behuzzuk a parent toggleMobile() függvényét is
    const { mobileMenuIsOpen, toggleMobile } = defineProps(['mobileMenuIsOpen', 'toggleMobile']);

</script>

<template>
  <div>
    <button
      type="button"
      class="focus:outline-none focus:ring-0"
      aria-expanded="false"
      @click="toggleMobile()"
    >
      <div class="text-2xl xs:text-xl mx-6 sm:mx-4 xs:mx-2">
<!-- TODO -->
        <font-awesome-icon
          v-if="mobileMenuIsOpen"
          :icon="['fas', 'times']"
        />
        <font-awesome-icon v-else :icon="['fas', 'bars']" />
      </div>
      <div class="xs:hidden text-xs">
        {{ $t('Menu') }}
      </div>
      <span class="sr-only">{{ $t('Menu') }}</span>
    </button>
  </div>
</template>
