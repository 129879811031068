<script setup>

  /**
   * 
   * Komponens: A webshop alap pénznemére vonatkozó szöveges információt jelenít meg:
   * Pl: "A fizetendő összeget Forintban számoljuk el."
   * 
   * Ez olyan webshopok esetében van így, ahol a vásárlás, szállítás, stb. a megjelenítési
   * beállítástól függetlenül, fixen, egy adott pénznem szerint történik.
   * 
  */

  // props: 
  const { webshopFixChargeCurrency } = defineProps({
    webshopFixChargeCurrency: { type: String, required: false, default: 'HUF' },
  });

</script>

<template>
  {{ $t(`We charge you in ${webshopFixChargeCurrency}`) }}
</template>
